import { register } from 'register-service-worker'

const { BASE_URL, NODE_ENV } = process.env

const validEnvs = ['production', 'test', 'development']
const registerServiceWorker = validEnvs.includes(NODE_ENV)
const logger = console

const SERVICE_WORKER = {
  url: `${BASE_URL}service-worker.js`,
  config: {
    ready() {
      logger.info(
        'The service worker is ready.\n'
          + 'For details, visit https://goo.gl/AFskqB',
      )
    },
    registered() {
      logger.info('The service worker has been registered.')
    },
    cached() {
      logger.info('Content has been cached for offline use.')
    },
    updatefound() {
      logger.info('New content is downloading.')
    },
    updated(registration) {
      logger.info('New content is available.')

      const event = new CustomEvent('swUpdated', { detail: registration })

      document.dispatchEvent(event)
    },
    offline() {
      logger.info(
        'No internet connection found. App is running in offline mode.',
      )
    },
    error(error) {
      logger.error('Error during service worker registration:', error)
    },
  },
  set() {
    const { config, url } = this

    register(url, config)
  },
}

if (registerServiceWorker) {
  SERVICE_WORKER.set()
}
