import PATHS from 'src/router/paths'

const summaries = {
  [PATHS.REPORTS.ADX_HEALTH]: {
    IMPRESSIONS: {
      didFail: false,
      format: 'number',
      key: 'adImpressions',
      id: 'IMPRESSIONS',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Impressions',
      total: 0,
    },
    IMPRESSIONS_PERCENTAGE: {
      average: 0,
      calculation: 'average',
      didFail: false,
      format: 'percentage',
      key: 'adImpressionPerc',
      id: 'IMPRESSIONS_PERCENTAGE',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Impressions %',
      total: 0,
    },
    ECPM: {
      average: 0,
      calculation: 'average',
      didFail: false,
      format: 'currency',
      key: 'adEcpm',
      id: 'ECPM',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Avg eCPM',
      total: 0,
    },
    ESTIMATED_REVENUE: {
      didFail: false,
      format: 'dollars',
      key: 'estimatedRevenue',
      id: 'ESTIMATED_REVENUE',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Est. Revenue',
      total: 0,
    },
  },
  [PATHS.REPORTS.NETWORK_HEALTH]: {
    IMPRESSIONS_UNFILLED: {
      didFail: false,
      format: 'number',
      key: 'unfilledImpressions',
      id: 'IMPRESSIONS_UNFILLED',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Unfilled Imps',
      total: 0,
    },
    IMPRESSIONS_TOTAL: {
      didFail: false,
      format: 'number',
      key: 'totalImpressions',
      id: 'IMPRESSIONS_TOTAL',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Total Imps',
      total: 0,
    },
    IMPRESSIONS_ALL: {
      didFail: false,
      format: 'number',
      key: 'allImpressions',
      id: 'IMPRESSIONS_ALL',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'All Imps',
      total: 0,
    },
    UNFILLED_PERCENTAGE: {
      average: 0,
      calculation: 'average',
      didFail: false,
      format: 'percentage',
      key: 'unfilledPercentage',
      id: 'UNFILLED_PERCENTAGE',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Unfilled %',
      total: 0,
    },
    GROSS_REVENUE: {
      didFail: false,
      format: 'dollars',
      key: 'revenue',
      id: 'GROSS_REVENUE',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Est. Revenue',
      total: 0,
    },
  },
  [PATHS.REPORTS.YIELD_PARTNER]: {
    // impressions, revenue, ecpm
    IMPRESSIONS_FILLED: {
      didFail: false,
      format: 'number',
      key: 'yieldImpressions',
      id: 'IMPRESSIONS_FILLED',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Imps Filled',
      total: 0,
    },
    ECPM: {
      average: 0,
      calculation: 'average',
      didFail: false,
      format: 'currency',
      key: 'yieldEstimatedCPM',
      id: 'ECPM',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'eCPM',
      total: 0,
    },
    GROSS_REVENUE: {
      didFail: false,
      format: 'dollars',
      key: 'yieldEstimatedRevenue',
      id: 'GROSS_REVENUE',
      isLoading: true,
      items: [],
      mtd: false,
      styles: {
        background: 'primary',
        text: 'white',
        value: 'white',
      },
      subtitle: undefined,
      title: 'Est. Revenue',
      total: 0,
    },
  },
}

export default summaries
